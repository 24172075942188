import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Span, List, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy-policy"} />
		<Helmet>
			<title>
				Bos Balance: Financial Expert for Expats & Freelancers in the Netherlands
			</title>
			<meta name={"description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:title"} content={"Bos Balance: Financial Expert for Expats & Freelancers in Netherlands"} />
			<meta property={"og:description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
		</Helmet>
		<Components.TopNavBar />
		<Section>
			<Text margin="25px 2 2 2" font="--headline1" color="--primary" as="h1">
				Privacy Policy
			</Text>
			<Text margin="25px 2 2 2" font="--lead" color="--primary">
				<Strong>
					Privacy Policy for Bos Balance
				</Strong>
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Last Updated: 04 February 2024
				</Span>
				<br />
			</Text>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				1. Introduction
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				Welcome to Bos Balance. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, store, and disclose your personal information in accordance with our website and services.
			</Text>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				2. Information We Collect
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				We collect information that you provide to us directly, such as when you contact us, use our services, or register on our website. This information may include but is not limited to:
			</Text>
			<List
				margin="25px 2 2 2"
				padding="0px 0px 0px 20px"
				list-style-type="disc"
				as="ul"
				color="--primary"
			>
				<Text margin="0px 0px 0px 0px">
					Your name, email address, and contact details
				</Text>
				<Text margin="0px 0px 0px 0px">
					Financial and accounting information relevant to the services we provide
				</Text>
				<Text margin="0px 0px 0px 0px">
					Any other information you choose to provide to us
				</Text>
			</List>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				3. Use of Your Information
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				The information we collect is used for the following purposes:
			</Text>
			<List
				margin="25px 2 2 2"
				padding="0px 0px 0px 20px"
				list-style-type="disc"
				as="ul"
				color="--primary"
			>
				<Text margin="25px 2 2 2" color="--primary">
					To provide and improve our services
				</Text>
				<Text margin="25px 2 2 2" color="--primary">
					To communicate with you about your use of our services
				</Text>
				<Text margin="25px 2 2 2" color="--primary">
					To comply with legal and regulatory obligations
				</Text>
				<Text margin="25px 2 2 2" color="--primary">
					For other purposes with your consent
				</Text>
			</List>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				4. Data Storage and Security
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				We take the security of your personal information seriously and have implemented appropriate physical, technical, and administrative measures to protect the information we collect.
			</Text>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				5. Sharing and Disclosure of Information
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				We do not sell or rent your personal information to third parties. We may disclose your information:
			</Text>
			<List
				margin="25px 2 2 2"
				padding="0px 0px 0px 20px"
				list-style-type="disc"
				as="ul"
				color="--primary"
			>
				<Text margin="25px 2 2 2" color="--primary">
					To service providers who assist us in operating our business
				</Text>
				<Text margin="25px 2 2 2" color="--primary">
					As required by law or in response to legal requests
				</Text>
				<Text margin="25px 2 2 2" color="--primary">
					In connection with a sale, merger, or business transfer
				</Text>
			</List>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				6. Your Rights
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				You have the right to access, correct, or delete your personal information held by us. You can exercise these rights by contacting us at the details provided below.
			</Text>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				7. Cookie Policy
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				Our website uses cookies to enhance user experience and analyse site usage.
			</Text>
			<Text margin="25px 2 2 2" font="--lead" color="--primary">
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Types of Cookies We Use:
				</Span>
			</Text>
			<List
				margin="0px 0px 0px 0px"
				padding="0px 0px 20px 20px"
				list-style-type="disc"
				as="ul"
				display="grid"
				grid-gap="16px"
				color="--primary"
			>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Essential Cookies:
					</Strong>
					{" "}These are necessary for the basic functioning of the website. They enable core features such as security, network management, and accessibility.
				</Text>
				<Text margin="0px 0px 0px 0px">
					<Strong>
						Analytics Cookies:
					</Strong>
					{" "}We use Google Analytics through Google Tag Manager to gather information on how visitors interact with our site. This helps us understand user behaviour and improve our site’s performance. The data collected is anonymous and is used solely for statistical purposes.
				</Text>
			</List>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				By using our website, you consent to the use of these cookies. You can manage your cookie preferences in your browser settings at any time. For more information on how Google Analytics collects and processes data, please refer to the{" "}
				<Link href="https://policies.google.com/privacy" target="_blank">
					Google Privacy Policy
				</Link>
				.
			</Text>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				8. Third-Party Links
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				Our website may contain links to other websites. We are not responsible for the privacy practices or content of these external sites.
			</Text>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				9. Compliance with Legal Standards
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				We adhere to applicable data protection laws, including the GDPR for EU residents.
			</Text>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				10. Changes to Our Privacy Policy
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website.
			</Text>
			<Text margin="25px 2 2 2" font="--headline2" color="--primary" as="h2">
				11. Contact Us
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				If you have any questions about this Privacy Policy, please contact us at:
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				<Strong>
					Phone:
				</Strong>
				{" "}+31 (0) 616 555 767
			</Text>
			<Text margin="25px 2 2 2" font="--base" color="--primary">
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Address:{" "}
				</Strong>
				Westzanerdijk, Zaandam, 1507 AL, Netherlands
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bf71cb867c86001f1b6703"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});